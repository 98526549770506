import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import { BrowserRouter as Router, Link } from "react-router-dom";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
          <Row>
            <Col lg="5">
              <p>
              Concord airport taxi is operating its taxi services in Concord and all other nearby cities in Massachusetts from last 14 years. We have our taxi services from Concord to Logan International airport and nearby cities. Depending on your needs, we provide taxi, SUV, Minivan, VAN or Sedan services. We are always beating the prices and services in Concord city.
              </p>
            </Col>
            <Col lg="3" className="pl-5">
              <h4>Navigations</h4>
               
              <ListGroup>
                <ListGroup.Item>
                  <a href="/about">About us</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/service-area">Service Area</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/fleet">Fleet</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/contact">Contact Us</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/privacy-policy">Privacy Policy</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col lg="4">
              <h4>Contact Us</h4>
              <p>
                <strong>Office: </strong>
                Concord Airport Taxi & Car Services 300 Baker Avenue, Concord,
                MA 01742
              </p>
              <p>
                <strong>Phone: </strong>
                <a href="tel:978-371-2224">978-371-2224</a>
              </p>
              <p>
                <strong>Email: </strong>
                <a href="mailto:info@concordnairporttaxi.com">
                  info@concordnairporttaxi.com
                </a>
              </p>
              <div className="social-icons">
                <a href="#!">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <div className="copyright text-center">
            Copyright {year} Concord Airport Cars Limited. All rights reserved.
          </div>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
