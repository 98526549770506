import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/car-icon.png";
import Img2 from "../images/driver-icon.png";
import Img3 from "../images/airport-icon.png";

const ThreeColWithImageHover = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "Intercity rides",
      description: "Your stress-free solution for traveling between cities,",
      img: Img1,
    },
    {
      title: "Chauffeur hailing",
      description: "Your stress-free solution for traveling between cities,",
      img: Img2, 
    },
    {
      title: "Airport transfers",
      description: "Your stress-free solution for traveling between cities,",
      img: Img3, 
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper half__bg pt-0">
      <Container fluid>
        <Row>
          <Col lg="4">
            <h2 className=" text-left section__title hav__icon">
              {SectionTitle}
            </h2>
          </Col>
          <Col lg="8">
            <p className="text-left">
              ACTON MA TO BOSTON AIRPORT TRANSFER: We provide reliable and on
              time airport taxi service from Acton MA to Boston Logan Airport
              International Airport, Acton MA to Logan Airport, Acton to
              Manchester, Acton to Portland ME International Jetport, Acton to
              Providence RI TF Green/Warwick, Acton to Bradley CT Intl, Acton to
              Burlington VT Airport. All our rates are affordable and there are
              no hidden charges as well.
            </p>
          </Col>
        </Row>

        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimagehover__unit" xs lg="4" key={i}>
                <img src={info.img} alt={info.title || ""} />
                <h4>{info.title || ""}</h4>
                <p className="description">{info.description || ""}</p>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImageHover;
