import "./App.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./pages/Index";
import About from "./pages/About";
import ServiceArea from "./pages/ServiceArea";
import Fleet from "./pages/Fleet";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/service-area">
          <ServiceArea />
        </Route>
        <Route path="/fleet">
          <Fleet />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/privacy-policy">
          <Privacy />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
