import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";

import logo from "../images/logo.png";

const HeaderComp = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const openNav = () => {
    document.getElementById("sidenav").style.left = "0px";
  };
  const closeThisNav = () => {
    document.getElementById("sidenav").style.left = "-250px";
  };

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.desc} />
      </Helmet>
      <Navbar className="Nav__wrapper">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} />
          
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="m-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                href="/"
                className={splitLocation[1] === "" ? "active" : ""}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/about"
                className={splitLocation[1] === "about" ? "active" : ""}
              >
                About Us
              </Nav.Link>

              <Nav.Link
                href="/service-area"
                className={splitLocation[1] === "service-area" ? "active" : ""}
              >
                Service Area
              </Nav.Link>
              <Nav.Link
                href="/fleet"
                className={splitLocation[1] === "fleet" ? "active" : ""}
              >
                Fleet
              </Nav.Link>
              <Nav.Link
                href="/contact"
                className={splitLocation[1] === "contact" ? "active" : ""}
              >
                Contact
              </Nav.Link>
            </Nav>

            <a href="https://passenger.concordairporttaxi.com" className="link__text">
              Login
            </a>
            <Button className="btn__main" onClick={()=> window.location.href='https://passenger.concordairporttaxi.com/getphone'}>Register</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="mob__nav">
        <a href="/"><img src={logo} /></a>

        <span className="open__nav" onClick={openNav}>
          <i className="fa-solid fa-bars"></i>
        </span>
      </section>

      <div id="sidenav" className="sidenav">
        <span className="close__nav" onClick={closeThisNav}>
          <i className="fa-solid fa-times"></i> Close
        </span>
        <a href="/" className={splitLocation[1] === "" ? "active" : ""}>
          Home
        </a>

        <a
          href="/about"
          className={splitLocation[1] === "about" ? "active" : ""}
        >
          About Us
        </a>

        <a
          href="/service-area"
          className={splitLocation[1] === "service-area" ? "active" : ""}
        >
          Service Area
        </a>
        <a
          href="/fleet"
          className={splitLocation[1] === "fleet" ? "active" : ""}
        >
          Fleet
        </a>
        <a
          href="/contact"
          className={splitLocation[1] === "contact" ? "active" : ""}
        >
          Contact
        </a>
      </div>
    </>
  );
};

export default HeaderComp;
