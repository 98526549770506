import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import Banner from "../images/about-banner.png";

import FooterComp from "../components/FooterComp";
import ThreeColWithImage from "../components/ThreeColWithImage";

const Contact = () => {
  return (
    <>
      <Nav
        title="Concord Airport Fleets Service | Concord Airport Car"
        desc="Concord Airport Taxi Services, Concord Airport Car Cambridge, Concord Airport Minivan Service Concord Airport Car Burlington, Concord Airport Car, Concord Airport Minivan Service, Concord Airport Transfer, Concord Airport Transfers, Concord Airport Shuttle, Concord Airport Car"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="fleet-page">
        <ThreeColWithImage SectionTitle="Our Fleet" />
      </div>
      <FooterComp />
    </>
  );
};

export default Contact;
