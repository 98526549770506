import React from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import review from "../images/reviweer.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "Mr. Man",
      date: "May 8 2022",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun.",
    },
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "John Doe",
      date: "July 8 2022",
      description:
        "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
  ];

  if (!Info) Info = defaultInfo;
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="section__gap homepage-review  ">
        <Container fluid>
          <h2 className="section__title  hav__icon text-left">
            {SectionTitle}
          </h2>
          <div className="slider__wrapper">
            <Slider {...settings}>
              {Info.map((info, i) => (
                <div className="reviewitem" key={i}>
                  <p className="text-left">{info.description}</p>
                  <div className="reviewer-info">
                    <img src={info.icon} alt="" />
                    <h5>
                      {info.title}
                      <small>
                        CEO at <span>@viano</span>
                      </small>
                    </h5>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
