import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";

const ThreeColWithImage = ({ Data = null, SectionTitle }) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img1,
      rating: "5.0",
      capacity: "1-2",
      price: "$250",
      url: {},
    },
    {
      title: "3-PAX SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img2,
      rating: "5.0",
      price: "$460",
      capacity: "2-3",
      url: {},
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      price: "$670",
      capacity: "6-7",
      url: {},
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      capacity: "6-7",
      price: "$670",
      url: {},
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="section__gap threecolwithimage__wrapper ">
      <Container fluid>
        <div className="text-center">
          <h2 className="section__title hav__icon centered">{SectionTitle}</h2>
          <p className="w-60">
            Our quality range of Boston Airport fleets includes Premium Sedan, 2
            to 3 premium sedan, 6 to 7 passenger minivan, 4 passenger minivan, 3
            passenger SUV, 6 passenger Minivan and 10 to 12 passenger group van.
          </p>
        </div>
        <div className="text-center">
          <Row>
            {Data.map((info, i) => (
              <Col className="threecolwithimage__unit" xs lg="3" key={i}>
                <figure>
                  <img src={info.img} alt={info.title || ""} />
                </figure>
                <div className="fleet__info">
                  <h4>{info.title || ""}</h4>
                  <p className="description">{info.description || ""}</p>
                  <div className="rating__wrap">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="mob__fleet__info">
                    <span>
                      <i className="fa fa-star"></i> ({info.rating})
                    </span>
                    <span>
                      <i class="fa-regular fa-user"></i> {info.capacity}
                    </span>
                  </div>
                  <div className="price-box">
                    <h5>
                      {/* <i class="fa-regular fa-heart"></i> Wishlist */}
                    </h5>
                    <Button className="btn__main">
                      <i class="fa-regular fa-calendar"></i> &nbsp; View More
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
