import react from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftIconMediaPlain from "./LefticonMediaPlain";

import app from "../images/appscreen.png";
import check from "../images/check.png";
import ios from "../images/app-store.png";
import play from "../images/googlepay.png";

const TwoColAppSection = () => {
  return (
    <>
      <div className="app__section__with__bg section__gap">
        <Container fluid>
          <Row>
            <Col xs lg="6">
              <h4 className="section__title text-left mt-5">
                Download the free Concord airport taxi app
              </h4>
              <p>for fasted, easier booking and exclusive deals.</p>
              <a href="">
                <img src={ios} alt="image " />
              </a>{" "}
              &nbsp;
              <a href="">
                <img src={play} alt="image " />
              </a>
            </Col>
            <Col xs lg="6" className="text-right">
              <img src={app} alt="image " />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TwoColAppSection;
