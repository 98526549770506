import React, { Component } from "react";
import Quote from "../components/QuoteComp";
import Button from "react-bootstrap/Button";
import Banner from "../images/banner.png";

const MainBannerComp = () => {
  return (
    <div className="hp__banner">
      <img src={Banner} alt="banner" />
      
      <div className="banner__text">
        <p className="text__yellow">A reliable taxi cab & town car services</p>
        <h1>
        Taxi to Boston International Airport
        </h1>
        <p>
        Are you looking for a reliable Boston cab companies to the airport in Acton. Your search is over as Acton airport taxi provides the best Boston cab fares and professional car services to client.
        </p>
        
      </div>
      <Quote />
    </div>
  );
};

export default MainBannerComp;
