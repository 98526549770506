import React from "react";
import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import ThreeColWithImage from "../components/ThreeColWithImage";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp";
import ThreeColWithImageHover from "../components/ThreeColWithImageHover";
import { Container } from "react-bootstrap";

import logo1 from "../images/logo1.png";
import logo2 from "../images/logo2.png";
import logo3 from "../images/logo3.png";
import logo4 from "../images/logo4.png";
import logo5 from "../images/logo5.png";
import logo6 from "../images/logo6.png";
const Index = () => {
  return (
    <>
      <div className="App">
        <Nav
          title="Taxi cab services from Logan airport to Concord – Best and cheap"
          desc="Concord airport Taxi Company provides the best taxi cab and town car services from Logan International airport to Concord, MA."
        />
        <Banner />
        <section className="logowrapper">
          <Container fluid className="logolist">
            <div className="logo-wrap">
              <img src={logo1} alt="image" />
            </div>
            <div className="logo-wrap">
              <img src={logo2} alt="image" />
            </div>
            <div className="logo-wrap">
              <img src={logo3} alt="image" />
            </div>
            <div className="logo-wrap">
              <img src={logo4} alt="image" />
            </div>
            <div className="logo-wrap">
              <img src={logo5} alt="image" />
            </div>
            <div className="logo-wrap">
              <img src={logo6} alt="image" />
            </div>
          </Container>
        </section>
        <ThreeColWithImage SectionTitle="Our Fleet" />
        <ThreeColWithImageHover SectionTitle="Our services" />
        <ReviewSlider SectionTitle="What Our Customer says" />
        <TwoColAppSection />
        <section className="section__gap text-center w-70">
          <Container fluid>
            <h2 className="section__title">
              Traveling to Boston or Logan from your location?
            </h2>
            <p>
              Cheap and expensive is a relative word. We always keep our price
              lower than any other company in the city and we ensure you the
              best prices in the city.
            </p>
            <a href="" className="btn__main btn__round">
              Subscribe Now
            </a>
          </Container>
        </section>
        <FooterComp />
      </div>
    </>
  );
};

export default Index;
