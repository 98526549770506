import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import TwoColAppSection from "../components/TwoColAppSection";
import FooterComp from "../components/FooterComp";

import ListGroup from "react-bootstrap/ListGroup";

const About = () => {
  return (
    <>
      <Nav
        title="Concord to Logan airport taxi services – Concord Airport Taxi"
        desc="Reliable taxi services with affordable fares from Concord to Logan International airport in Massachusetts. Unbeatable prices and services we offer."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap ">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
            About Us
          </h2>
          <Row>
            <Col lg="12">
              <h4 className="mb-4  small__title ">
                Concord to Logan airport taxi services – Concord Airport Taxi
              </h4>
              <p className="text-left">
                Concord airport taxi is operating its taxi services in Concord
                and all other nearby cities in Massachusetts from last 14 years.
                We have our taxi services from Concord to Logan International
                airport and nearby cities. Depending on your needs, we provide
                taxi, SUV, Minivan, VAN or Sedan services. We are always beating
                the prices and services in Concord city.
              </p>
              <p>
                Lowest price from Concord to Boston Logan international airport-
                you will find our price lower than any other transport services
                compared to the same quality of services. We are here to make
                you happy with our services and if you need to do more for your
                satisfaction, please let us know. We will try to satisfy you at
                the best.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-5 text-center">
              <h4 className="mb-4  ">
                Concord to Logan airport taxi services – Concord Airport Taxi
              </h4>
              <ListGroup className="box-list-inline">
                <ListGroup.Item>No hidden charges</ListGroup.Item>
                <ListGroup.Item>No waiting in line</ListGroup.Item>
                <ListGroup.Item>Luxurious car for your ride</ListGroup.Item>
                <ListGroup.Item>Best taxi rates in the city</ListGroup.Item>
                <ListGroup.Item>
                  We are on time for each of your call
                </ListGroup.Item>
                <ListGroup.Item>
                  Up 6 passengers in Minivan and 4 bags
                </ListGroup.Item>
                <ListGroup.Item>
                  Spacious space for your affordable journey
                </ListGroup.Item>
                <ListGroup.Item>
                  Flat fares as your choice in Concord, Massachusetts
                </ListGroup.Item>
                <ListGroup.Item>
                  Instant price calculator from Logan airport to Concord
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <TwoColAppSection />
      <section className="section__gap text-center w-70">
        <Container fluid>
          <h2 className="section__title">
            Traveling to Boston or Logan from your location?
          </h2>
          <p>
            Cheap and expensive is a relative word. We always keep our price
            lower than any other company in the city and we ensure you the best
            prices in the city.
          </p>
          <a href="" className="btn__main btn__round">
            Subscribe Now
          </a>
        </Container>
      </section>
      <FooterComp />
    </>
  );
};

export default About;
