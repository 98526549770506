import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";

import ListGroup from "react-bootstrap/ListGroup";
import FooterComp from "../components/FooterComp";

const ServiceArea = () => {
  return (
    <>
    <Nav
        title="Logan airport to Concord taxi service area and fares – Concord Airport Taxi"
        desc="We provide taxi, town car and limo and SUV services in all areas of Concord from Logan airport with affordable prices. Get your fares from Concord to Logan airport for taxi, Limo or SUV services."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                ServiceArea{" "}
              </h2>
            </Col>
            <Col lg="12">
              <h4 className="mb-4  small__title ">
                Logan airport to Concord taxi service area and fares – Concord
                Airport Taxi
              </h4>
              <p className="text-left">
                Concord Airport Taxi provides taxi, town car and limo, SUV and
                mini-van services from Logan airport to all the areas of Concord
                city of Massachusetts. If you need taxi services from any areas
                of Concord to Boston Logan International airport or
                Manchester–Boston Regional Airport or Laurence G Hanscom Field
                or T. F. Green Airport we can provide you affordable taxi fares
                and high-quality services with our comfortable sedan, town car
                and limo, SUV or Minivan. We have our satisfied clients in the
                following areas of Concord city.
              </p>
              <p>
                Our fares are comparatively cheap compared to the same services
                from any other company. We are promised to make you happy and
                satisfy with our taxi, town car and limo, SUV or Minivan
                services. You can easily calculate the fares from our online
                price calculator to and from the airport from anywhere in
                Concord city.
              </p>
            </Col>
            <Col lg="3" className="service__area_airport">
              <a href="https://www.massport.com/logan-airport/" target="_blank">
                <i class="fa-solid fa-plane-departure"></i>
                Boston Logan International airport
              </a>
            </Col>
            <Col lg="3" className="service__area_airport">
              <a href="https://www.flymanchester.com/" target="_blank">
                <i class="fa-solid fa-plane-departure"></i>
                Manchester–Boston Regional Airport
              </a>
            </Col>
            <Col lg="3" className="service__area_airport">
              <a href="https://www.massport.com/hanscom-field/" target="_blank">
                <i class="fa-solid fa-plane-departure"></i>
                Laurence G Hanscom Field
              </a>
            </Col>
            <Col lg="3" className="service__area_airport">
              <a href="https://www.flyri.com/" target="_blank">
                <i class="fa-solid fa-plane-departure"></i>
                T. F. Green Airport
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="section__gap bg-dim  service__area__list text-center">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h4 className="mb-4  ">
                Boston Logan International Airport Transportation services for
                the following areas
              </h4>
              <p className="text-center">
                We are providing transport services from Concord Airport to each
                part of the city including the following interesting points:
              </p>
              <ListGroup className="box-list-inline">
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> No hidden charges
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Barrett's Farm
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Reuben Brown House
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Concord Art
                  Association
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Concord Free Public
                  Library
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Concord Museum
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Corinthian Lodge Egg
                  Rock
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Emerson Hospital
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Ralph Waldo Emerson
                  House
                </ListGroup.Item>

                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Estabrook Woods
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Fairyland Pond
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> First Parish Church
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Great Meadows
                  National Wildlife Refuge
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Massachusetts
                  Correctional Institution – Concord
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Minute Man National
                  Historical Park
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Northeastern
                  Correctional Center
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> The Old Manse
                </ListGroup.Item>

                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Old North Bridge
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Orchard House
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Punkatasset Hill
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Sleepy Hollow
                  Cemetery
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Walden Pond
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> The Wayside
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Wheeler-Minot
                  Farmhouseu
                </ListGroup.Item>
                <ListGroup.Item>
                  <i class="fa-solid fa-location-dot"></i> Wright's Tavern
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterComp />
    </>
  );
};

export default ServiceArea;
