import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";

const Fleet = () => {
  return (
    <>
      <Nav
        title="Concord airport taxi - a reliable taxi cab service company in Concord"
        desc="A reliable taxi cab company in Concord, MA that you can always trust for your ride."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid >
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Contact Us
              </h2>
            </Col>
            <Col lg="3">
              <h4 className="mb-4  small__title ">
                Contact us for Concord ma to Logan airport taxi cab, town car
                and Limo services
              </h4>
              <p>
                Please find the contact information for a taxicab, town car and
                SUV services for Concord ma to Boston Logan International
                airport. Whenever you need a ride in Concord ma or any other
                nearby cities, please contact us. We intend to offer you the
                best price and will reach to you at your convenient time.
              </p>
              <p>
                <strong>Your trusted taxi services company</strong>
              </p>
            </Col>
            <Col lg="4">
              <h3 className=" small__title">
                <strong>Concord Airport Taxi</strong>
              </h3>
              <p>300 Baker Ave, Suite 300Concord, MA-01742</p>
              <p>
                <a href="tel:978-371-2224">978-371-2224</a>
              </p>
              <p>
                <a href="mailto:info@airportcarconcord.com">
                  info@airportcarconcord.com
                </a>
              </p>
              <p>
                <strong>
                  24/7 Service available. <br />
                  Phone reservation: 3:00AM - 11:00PM <br />
                  Online reservation: 24/7
                </strong>
              </p>
            </Col>
            <Col lg="5">
              <Form className="contact-form">
                <h3>Send your Queries</h3>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Full Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows="4" placeholder="Message" />
                </Form.Group>
                {/* <ReCAPTCHA sitekey="111" />, */}
                <Button className="btn-block btn__main">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Fleet;
